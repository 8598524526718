var render, staticRenderFns
import script from "./indexTemplate.vue?vue&type=script&lang=js"
export * from "./indexTemplate.vue?vue&type=script&lang=js"
import style0 from "./indexTemplate.vue?vue&type=style&index=0&id=37d32cd6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d32cd6",
  null
  
)

export default component.exports