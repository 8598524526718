<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'

export default {
  name: 'indexTemplate',
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmShowSpecialTemplate/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'templateName',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'templateName',
          title: '模板名称',
          align: 'left',
          width: '40%',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.templateImg} />
                <span>{text}</span>
              </div>
            )
          },
        },
        {
          dataIndex: 'linkUrl',
          title: '模板地址',
          align: 'left',
        },
        {
          dataIndex: 'showSpecialType',
          title: '模版类型',
          align: 'left',
          customRender: (text, records) => {
            switch (text) {
              case '0':
                return '专题页模板'
              case '1':
                return '富文本自定义'
              default:
                return ''
            }
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  //   let obj = Object.assign({}, records)
                  //   this.handlePopup(obj)
                  getAction('/farmShowSpecialTemplate/detail?id=' + records.id).then((res) => {
                    this.handlePopup(res.data)
                  })
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmShowSpecialTemplate',
                    params: {
                      id: records.id,
                    },
                  })
                },
              },
            ]
          },
        },
      ]
    },
    getDrawerForm(source = { sort: 50 }, formData) {
      const form = [
        {
          form: [
            {
              name: '模板名称',
              key: 'templateName',
              labelAlign: 'right',
              type: 'input',
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '模板地址',
              key: 'linkUrl',
              labelAlign: 'right',
              type: 'input',
              maxLength: 100,
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '模版类型',
              key: 'showSpecialType',
              type: 'radio',
              typeData: [
                {
                  name: '专题页模板',
                  value: '0',
                },
                {
                  name: '富文本自定义',
                  value: '1',
                },
              ],
            },
            {
              name: '缩略图',
              labelAlign: 'right',
              type: 'iconUpload',
              typeData: [
                {
                  maxLength: 1,
                  key: 'templateImg',
                  desc: '宽<750px 图片平铺 <br/> @2x  xhdpi',
                },
              ],
            },
          ],
        },
      ]
      return form
    },
    handlePopup(dataSource = { sort: 50 }) {
      let that = this
      apiTool.showDrawer({
        title: '设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(dataSource, formData),
          form: dataSource,
          //   formProps: {
          //     labelCol: { span: 3 },
          //     wrapperCol: { span: 20 }
          //   }
        },
        success: ({ data, setHidden }) => {
          if (!data.templateName) {
            this.$message.error('请填写模板名称')
            return
          } else if (!data.linkUrl) {
            this.$message.error('请填写模板地址')
            return
          } else {
            api.command[data.id ? 'edit' : 'create']
              .call(that, {
                url: '/farmShowSpecialTemplate',
                params: data,
              })
              .then(setHidden)
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        filterRightButtonKey={['refresh', 'setting']}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        showRowSelect={false}
      />
    )
  },
}
</script>
<style scoped lang="less">
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
